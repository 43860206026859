<template>
 <div>
   <div class="card">
     <div class="card-body">
       <div class="table-responsive">
         <table class="table table-sm table-bordered text-center" id="hub_list">
           <thead>
           <tr>
             <th>#</th>
             <th>Name</th>
             <th>Address</th>
             <th>Status</th>
             <th>Action</th>
           </tr>
           </thead>
           <tbody>
           <tr v-for="(hub, index) in hubs" :key="index">
             <td>{{ index + 1 }}</td>
             <td>{{ hub.name }}</td>
             <td>{{ hub.address }}</td>
             <td>
               <a-tag color="#87d068" v-if="hub.status == 1">
                 Active
               </a-tag>
               <a-tag color="#f50" v-else>
                 Inactive
               </a-tag>
             </td>
             <td>
               <router-link :to="{ name: 'hubProductAssign', params: { hub_id: hub.id } }" class="btn btn-sm btn-success mr-1" tag="a">
                 <i class="fa fa-tasks mr-1"/>Assign Product
               </router-link>
             </td>
           </tr>
           </tbody>
         </table>
         <a-skeleton active :loading="loading"></a-skeleton>
         <h5 class="text-center text-secondary" v-if="hubs.length === 0 && !flag">
           <Empty :image="simpleImage"></Empty>
         </h5>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      hubs: [],
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getHubs()
  },
  methods: {
    getHubs() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/hubs')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.hubs = response.data.hubs
          setTimeout(function() {
            $('#hub_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 25,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
